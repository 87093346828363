export const environment = {
  production: true,
  staging: false,
  apiEndpoint: 'https://api.services4eo.com/request',
  authority: 'https://triple-a.services4eo.com',
  clientId: '98732ef9-607b-4dd1-8a8d-9a53c2751fb8',
  scope: 'profile openid email user_name gsc4eo',
  apiKey: '9ec171e8-9609-4f5c-b9e3-cea4f35862f5',
  support: 'https://service4eo.atlassian.net/servicedesk',
  storeUrl: 'https://store.services4eo.com/'
};
